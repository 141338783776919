import React, { useState, useEffect } from 'react';
import { Spin } from 'antd'; // Importing Ant Design's Spin component for loading
import { SmileOutlined } from '@ant-design/icons'; // Ant Design's Smile icon
import './SurveyList.css'; // Importing the CSS file for styles

const SurveyList = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay (if needed, replace this with your actual API call)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1-second delay
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="survey-container">
      {loading ? (
        // Show loading spinner while simulating data fetch
        <div className="loading-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <div className="thank-you-message">
          <div id="header">
            <img src="/assets/images/logo.png" alt="Gumba Survey Logo" className="animated-logo" />
          </div>
          <h1>Welcome to Gumba Survey Tool!</h1>
          <p>
            The Gumba Survey Tool helps you gather and analyze survey data
            efficiently. Create, share, and evaluate surveys to gain valuable
            insights and make informed decisions.
          </p>
          <div className="store-links">
            <a
              href="https://apps.apple.com/gt/app/gumba-survey-tool/id6463199738"
              target="_blank"
              rel="noopener noreferrer"
              className="store-link"
            >
              <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="Download on the App Store"
                className="store-logo"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=co.tz.gumba&hl=en"
              target="_blank"
              rel="noopener noreferrer"
              className="store-link"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Get it on Google Play"
                className="store-logo"
              />
            </a>
          </div>
        </div>

      )}
    </div>
  );
};

export default SurveyList;
