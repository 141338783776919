// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SurveyForm from './components/SurveyForm';
import SurveyList from './components/SurveyList';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SurveyList />} /> {/* Home page showing list of surveys */}
        <Route path="/survey/:surveyId" element={<SurveyForm />} /> {/* Dynamic route for individual survey */}
      </Routes>
    </Router>
  );
};

export default App;
