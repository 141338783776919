import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Radio, Checkbox, DatePicker, Upload, message, Modal, Spin } from 'antd';
import { ChromeOutlined, MenuOutlined,EllipsisOutlined, SettingOutlined, EnvironmentOutlined } from '@ant-design/icons'; 
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { WarningOutlined, LockOutlined } from '@ant-design/icons';
import { CameraOutlined } from '@ant-design/icons';
import moment from 'moment';
import './SurveyForm.css';

const { Option } = Select;

const SurveyForm = () => {
    const [form] = Form.useForm(); 
    const { surveyId } = useParams();
    const [surveyData, setSurveyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [currentSection, setCurrentSection] = useState(0);
    const [userId] = useState(457);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+255');
    const [imageMaps] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileListState, setFileListState] = useState({});
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationAccessError, setLocationAccessError] = useState(false); 
    const [language, setLanguage] = useState('en');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const locationInstructions = {
        en: {
            title: "LOCATION ACCESS REQUIRED",
            message: "We could not access your location. Please ensure location access is enabled.",
            recommendation: "For best results, we recommend using the Google Chrome browser.",
            steps: [
                {
                    icon: <EllipsisOutlined className="vertical-ellipsis" />,
                    text: "Tap on the three-dot menu icon at the top-right corner of your browser."
                },
                {
                    icon: <SettingOutlined />,
                    text: "Scroll down and select Settings."
                },
                {
                    // icon: <SettingOutlined />,
                    text: "Under the 'Advanced' section, tap Site settings."
                },
                {
                    icon: <EnvironmentOutlined />,
                    text: "Tap Location and ensure it's set to 'Allow' for https://projectcleargumba.com."
                },
                {
                    icon: <EnvironmentOutlined />,
                    text: "Go back to the survey page and reload it. You should now be able to submit your location."
                }
            ]
        },
        sw: {
            title: "UTAMBUZI WA ENEO UNALOPATIKANA",
            message: "Hatuwezi kufikia eneo lako. Tafadhali hakikisha ufikiaji wa eneo umewezeshwa.",
            recommendation: "Kwa matokeo bora, tunapendekeza kutumia kivinjari cha Google Chrome.",
            steps: [
                {
                    icon: <EllipsisOutlined className="vertical-ellipsis" />,
                    text: "Bonyeza ikoni ya menyu yenye nukta tatu iliyopo kwenye kona ya juu kulia."
                },
                {
                    icon: <SettingOutlined />,
                    text: "Chagua Mipangilio (Settings)."
                },
                {
                    // icon: <SettingOutlined />,
                    text: "Chini ya sehemu ya 'Advanced', gusa Mipangilio ya Tovuti(Site settings)."
                },
                {
                    icon: <EnvironmentOutlined />,
                    text: "Bonyeza 'Location’ na hakikisha imewekwa kwenye kitufe cha ruhusu ('Allow') kwa tovuti ya https://projectcleargumba.com."
                },
                {
                    // icon: <EnvironmentOutlined />,
                    text: "Rudi kwenye ukurasa wa utafiti (survey).  Sasa utaweza kuwasilisha eneo lako unalopatikana."
                }
            ]
        }
    };

    useEffect(() => {
        localStorage.removeItem("surveyAnswers");
        localStorage.removeItem("formAttributes");
        // Fetch survey data
        if (surveyId) {
            axios.get(`${backendUrl}/surveys/${surveyId}`)
                .then(response => {
                    setSurveyData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    setLoading(false);
                    message.error('Failed to fetch survey data');
                });
        } else {
            setLoading(false);
            message.error('Survey ID is missing');
        }
                window.onload = () => {
            localStorage.removeItem("responses");
            localStorage.removeItem("formAttributes");
        };
        // Capture location when the component mounts
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    setLocationAccessError(false); // Reset error if location is successfully obtained
                },
                (error) => {
                    setLocationAccessError(true); // Set error if location access fails
                    message.error('Failed to get location. Please ensure location access is enabled.');
                }
            );
        } else {
            setLocationAccessError(true); // Set error if geolocation is not supported
            message.warning('Geolocation is not supported by this browser. Please use a modern browser like Chrome.');
        }
    }, [surveyId, backendUrl]);

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/\D/g, '');
        if (numericValue.length <= 9) {
            setPhoneNumber(numericValue);
        }
    };

    const renderQuestion = (question) => {
        if (!question) return null;
        switch (question.type) {
            case 'SHORT':
                return <Input placeholder={question.title} />;
            case 'PARAGRAPH':
                return <Input.TextArea rows={4} placeholder={question.title} />;
            case 'MULTIPLE':
                return (
                    <Radio.Group>
                        {question.answers.map(answer => (
                            <Radio key={answer.id} value={answer.title}>{answer.title}</Radio>
                        ))}
                    </Radio.Group>
                );
            case 'CHECKBOX':
                return (
                    <Checkbox.Group>
                        {question.answers.map(answer => (
                            <Checkbox key={answer.id} value={answer.title}>
                                {answer.title}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                );
            case 'DROPDOWN':
                return (
                    <Select placeholder={question.title}>
                        {question.answers.map(answer => (
                            <Option key={answer.id} value={answer.title}>{answer.title}</Option>
                        ))}
                    </Select>
                );
            case 'PHONE':
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Select
                            value={countryCode}
                            onChange={setCountryCode}
                            style={{ width: '90px', marginRight: '8px' }}
                        >
                            <Option value="+255">+255</Option>
                        </Select>
                        <Input
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                            placeholder="Enter phone number"
                            maxLength={9}
                            style={{ width: 'calc(100% - 100px)' }}
                            type="number"
                        />
                    </div>
                );
            case 'EMAIL':
                return <Input type="email" placeholder="Enter email address" />;
            case 'NUMBER':
                return <Input type="number" placeholder="Enter a number" />;
            case 'FILE':
                return (
                    <Upload
                        multiple={false} // Allow only one file
                        accept="image/*" // Restrict to image files
                        capture="camera" // Open the camera directly on supported devices
                        fileList={fileListState[question.id] || []} // Use per-question file list
                        onChange={({ fileList }) => setList(fileList, question.id)} // Update per-question file list
                        onRemove={(file) => handleFileRemove(file, question.id)} // Handle file removal
                        beforeUpload={() => false} // Prevent automatic upload
                        showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }} // Customize file list actions
                        listType="picture-card"
                    >
                        <CameraOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
                    </Upload>
                );
                
            case 'DATE':
                return <DatePicker placeholder="Select date" />;
            case 'TIME':
                return <DatePicker picker="time" placeholder="Select time" />;
            default:
                return null;
        }
    };
    const setList = (fileList, questionId) => {
        setFileListState(prevFileList => ({
            ...prevFileList,
            [questionId]: fileList,
        }));
    };
    const handleFileRemove = (file) => {
        setFileList((prevList) => prevList.filter(f => f.uid !== file.uid));
    };
    const handleSubmit = (values) => {
        setSubmitting(true);
    
        // Retrieve saved responses and formAttributes from localStorage
        const savedAnswers = JSON.parse(localStorage.getItem('responses')) || [];
        const savedFormAttributes = JSON.parse(localStorage.getItem('formAttributes')) || { data: [] };
    
        const lastSectionAnswers = [];
        surveyData.sections[surveyData.sections.length - 1].questions.forEach((question) => {
            let answer = values[question.title];
    
            if (question.type === 'DATE' || question.type === 'TIME') {
                answer = answer ? moment(answer).format('YYYY-MM-DD') : null;
                if (question.type === 'TIME') {
                    answer = answer ? moment(answer).format('HH:mm:ss') : null;
                }
            }
    
            if (answer && answer.length > 0) {
                // Handle array-based answers (multi-select) before saving them
                if (Array.isArray(answer)) {
                    answer = answer.filter(a => a).join(","); // Join array into comma-separated string
                } else if (typeof answer === "string" && answer.includes(",")) {
                    // If the answer is a string with commas, split it back into an array
                    answer = answer.split(",");
                }
    
                lastSectionAnswers.push({
                    answer,
                    questionNumber: question.id,
                    surveyId,
                    sectionId: surveyData.sections[surveyData.sections.length - 1].id,
                });
                savedFormAttributes.data.push({
                    answer,
                    questionNumber: question.id,
                    surveyId,
                    sectionId: surveyData.sections[surveyData.sections.length - 1].id,
                });
            }
        });
    
        // Combine previously saved answers and last section answers (no form data duplication)
        const allAnswers = [...savedAnswers, ...lastSectionAnswers];
    
        // Prepare the form data for submission
        const payload = {
            responses: [],
            location: {
                latitude: latitude,
                longitude: longitude,
                status: "PENDING",
            },
            imageMaps: imageMaps,
            form: {
                formAttributes: savedFormAttributes, // Only include formAttributes from localStorage
            },
        };
    
        // Create a map to track if a question has already been answered
        const answeredQuestions = new Map();
    
        // Add answers from saved responses and last section
        allAnswers.forEach((item) => {
            if (!answeredQuestions.has(item.questionNumber)) {
                // If the answer for this question hasn't been added yet, push it to the responses array
                payload.responses.push(item);
                answeredQuestions.set(item.questionNumber, true);
            }
        });
    
        // Upload files before submission if any
        const fileUrls = {}; // Use an object to track URLs for each question
        const uploadPromises = Object.keys(fileListState).map((questionId) => {
            const filesForQuestion = fileListState[questionId];
            const formData = new FormData();
    
            filesForQuestion.forEach(file => {
                formData.append('file', file.originFileObj); // Use the original file object
            });
    
            return axios.post(`${backendUrl}/files/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // Join file URLs with semicolons if they are from the same question
                const fileUrlsForQuestion = response.data.fileUrl || filesForQuestion.map(file => `${backendUrl}/files/${file.name}`).join(";");
                fileUrls[questionId] = fileUrlsForQuestion; // Store URLs by questionId
            })
            .catch((error) => {
                message.error('Failed to upload file: ' + filesForQuestion.map(f => f.name).join(", "));
            });
        });
    
        // Wait for all file uploads to finish before continuing
        Promise.all(uploadPromises)
        .then(() => {
            // Map file URLs to the corresponding answers
            let answers = [];
            surveyData.sections.forEach((section) => {
                section.questions.forEach((question) => {
                    let answer = values[question.title];
    
                    // Ensure that if it's a FILE type question, handle it separately
                    if (question.type === "FILE" && fileUrls[question.id]) {
                        // Use the file URLs stored for the specific question
                        const joinedFileUrls = fileUrls[question.id];
    
                        // Check for duplicates before adding the answer
                        if (!answeredQuestions.has(question.id)) {
                            answers.push({
                                answer: joinedFileUrls,
                                questionNumber: question.id,
                                surveyId,
                                sectionId: section.id,
                            });
                            payload.form.formAttributes.data.push({
                                answer: joinedFileUrls,
                                questionNumber: question.id,
                                surveyId,
                                sectionId: section.id,
                            });
                            answeredQuestions.set(question.id, true); // Mark question as answered
                        }
                    } else if (Array.isArray(answer) && question.type !== "FILE") {
                        // For array-based answers (not FILE), join with commas
                        if (answer && answer.length > 0) {
                            answer = answer.filter(a => a).join(","); // Join with commas and filter empty values
                        }
                    }
    
                    if (answer && answer.length > 0 && !answeredQuestions.has(question.id)) {
                        // Push non-file answers to both responses and formAttributes only if not already added
                        answers.push({
                            answer,
                            questionNumber: question.id,
                            surveyId,
                            sectionId: section.id,
                        });
                        payload.form.formAttributes.data.push({
                            answer,
                            questionNumber: question.id,
                            surveyId,
                            sectionId: section.id,
                        });
                        answeredQuestions.set(question.id, true); // Mark question as answered
                    }
                });
            });
    
            // Add the file responses to the responses array in payload
            payload.responses.push(...answers);
    
            // Submit the form data to the server
            axios.post(`${backendUrl}/responses/${userId}/${surveyId}`, payload)
                .then((response) => {
                    message.success("Response and form submitted successfully");
                    localStorage.removeItem('responses');
                    localStorage.removeItem('formAttributes');
    
                    Modal.confirm({
                        title: "Survey Submitted",
                        content: "Would you like to fill another survey?",
                        okText: "Yes",
                        onOk: () => window.location.reload(),
                        cancelText: "No",
                        onCancel: () => {
                            navigate("/");
                        },
                    });
                })
                .catch((error) => {
                    message.error("Failed to submit response");
                })
                .finally(() => {
                    setSubmitting(false);
                    // Optionally, clear the localStorage after submission
                    localStorage.removeItem('responses');
                    localStorage.removeItem('formAttributes');
                });
        })
        .catch(() => {
            setSubmitting(false);
        });
    };
    
    const handleNext = (e) => {
        e.preventDefault(); // Prevent form submission
    
        const values = form.getFieldsValue(); // Get current form values
        const answers = [];
        const formAttributes = {
            data: [], // Initialize formAttributes data
        };
    
        const answeredQuestions = new Map();
        const backendUrl = process.env.REACT_APP_BACKEND_URL; // Ensure the backend URL is available
    
        let hasError = false; // Flag to track if there's an error
    
        surveyData.sections[currentSection].questions.forEach((question) => {
            let answer = values[question.title];
    
            // Handle DATE and TIME question types
            if (question.type === 'DATE' || question.type === 'TIME') {
                answer = answer ? moment(answer).format('YYYY-MM-DD') : null;
                if (question.type === 'TIME') {
                    answer = answer ? moment(answer).format('HH:mm:ss') : null;
                }
            }
    
            // Handle FILE question type (check if file is attached)
            if (question.type === 'FILE') {
                if (!fileListState[question.id] || fileListState[question.id].length === 0) {
                    // If no file is attached, show error for FILE question
                    hasError = true;
                    form.setFields([
                        {
                            name: question.title,
                            errors: ['Please upload a file.'],
                        },
                    ]);
                } else if (fileListState[question.id] && fileListState[question.id].length > 0) {
                    // If a file is attached, construct the file URL(s)
                    const fileUrls = fileListState[question.id].map(file => `${backendUrl}/files/${file.name}`).join(";");
    
                    if (!answeredQuestions.has(question.id)) {
                        answers.push({
                            answer: fileUrls,
                            questionNumber: question.id,
                            surveyId,
                            sectionId: surveyData.sections[currentSection].id,
                        });
                        formAttributes.data.push({
                            answer: fileUrls,
                            questionNumber: question.id,
                            surveyId,
                            sectionId: surveyData.sections[currentSection].id,
                        });
                        answeredQuestions.set(question.id, true);
                    }
                }
            }
    
            // Handle array-based answers for other question types
            if (Array.isArray(answer) && question.type !== 'FILE') {
                answer = answer.join(",");
            }
    
            // Add non-empty answers for other types of questions
            if (answer && answer.length > 0) {
                answers.push({
                    answer,
                    questionNumber: question.id,
                    surveyId,
                    sectionId: surveyData.sections[currentSection].id,
                });
    
                formAttributes.data.push({
                    answer,
                    questionNumber: question.id,
                    surveyId,
                    sectionId: surveyData.sections[currentSection].id,
                });
            } else if (question.type !== 'FILE') {
                // For other types of questions, show an error if no answer
                hasError = true;
                form.setFields([
                    {
                        name: question.title,
                        errors: ['This question is required.'],
                    },
                ]);
            }
        });
    
        // If there's an error, stop proceeding to the next step
        if (hasError) {
            return; // Do not proceed to the next section
        }
    
        // Retrieve existing responses from localStorage and merge them
        const existingResponses = JSON.parse(localStorage.getItem('responses')) || [];
        const existingFormAttributes = JSON.parse(localStorage.getItem('formAttributes')) || { data: [] };
    
        // Update responses and formAttributes for the current section
        const updatedResponses = [...existingResponses.filter(res => res.sectionId !== surveyData.sections[currentSection].id), ...answers];
        const updatedFormAttributes = {
            data: [
                ...existingFormAttributes.data.filter(attr => attr.sectionId !== surveyData.sections[currentSection].id),
                ...formAttributes.data,
            ],
        };
    
        // Save the merged payload back to localStorage
        localStorage.setItem('responses', JSON.stringify(updatedResponses));
        localStorage.setItem('formAttributes', JSON.stringify(updatedFormAttributes));
    
        // Move to the next section if it's not the last one
        if (currentSection < surveyData.sections.length - 1) {
            setCurrentSection(currentSection + 1);
        }
    };

    const handlePrevious = () => {
        // Retrieve existing responses and form attributes from localStorage
        const existingResponses = JSON.parse(localStorage.getItem('responses')) || [];
        const existingFormAttributes = JSON.parse(localStorage.getItem('formAttributes')) || { data: [] };
    
        // Filter out responses and attributes of the current section
        const updatedResponses = existingResponses.filter(res => res.sectionId !== surveyData.sections[currentSection].id);
        const updatedFormAttributes = {
            data: existingFormAttributes.data.filter(attr => attr.sectionId !== surveyData.sections[currentSection].id),
        };
    
        // Save the updated payload back to localStorage
        localStorage.setItem('responses', JSON.stringify(updatedResponses));
        localStorage.setItem('formAttributes', JSON.stringify(updatedFormAttributes));
    
        // Move to the previous section if it's not the first one
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        }
    };
    
    // Clear localStorage keys on page reload
    window.onload = () => {
        localStorage.removeItem('surveyAnswers');
        localStorage.removeItem('formAttributes');
    };


    if (loading) {
        return (
            <div className="loading-container">
                <Spin size="large" />
                <p>Loading Survey Data.....</p>
            </div>
        );
    }

    if (!surveyData) {
        return (
            <div style={{ fontSize: '24px', color: '#888', textAlign: 'center', marginTop: '20px' }}>
                <WarningOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                <p>No survey data available</p>
            </div>
        );
    }

    if (surveyData.accessibility !== 'PUBLIC') {
        return (
            <div style={{ fontSize: '24px', color: '#888', textAlign: 'center', marginTop: '20px' }}>
                <LockOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                <p>This survey is protected</p>
            </div>
        );
    }

    if (surveyData.publish !== true ) {
        return (
            <div style={{ fontSize: '24px', color: '#888', textAlign: 'center', marginTop: '20px' }}>
                <LockOutlined style={{ fontSize: '48px', color: '#ff4d4f' }} />
                <p>This survey is not Published </p>
            </div>
        );
    }

    const currentSectionData = surveyData.sections[currentSection];

    return (
        <div className="form-container">
            <Form form={form} onFinish={handleSubmit}>
                <h2>{surveyData.title || 'Survey Title'}</h2>
                <p>{surveyData.description || 'Survey Description'}</p>
                <div className="form-section">
                    <h3>{surveyData.sections[currentSection].title}</h3>
                    <p>{surveyData.sections[currentSection].subtitle}</p>
                    {surveyData.sections[currentSection].questions.map((question) => (
                        <Form.Item
                            key={question.id}
                            name={question.title}
                            label={question.title}
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            rules={[{ required: question.isRequired, message: `${question.title} is required` }]}
                        >
                            {renderQuestion(question)}
                        </Form.Item>
                    ))}
                </div>

                <div className="form-navigation">
                    {currentSection > 0 && (
                        <Button onClick={handlePrevious} className="prev-btn">Previous</Button>
                    )}
                    {currentSection < surveyData.sections.length - 1 ? (
                        <Button type="primary" onClick={handleNext} className="next-btn">Next</Button>
                    ) : (
                        <Button type="primary" htmlType="submit" className="submit-btn">Submit</Button>
                    )}
                </div>
            </Form>
            {submitting && (
                <Modal
                    visible={submitting}
                    footer={null}
                    closable={false}
                    centered
                    className="submit-modal"
                    width="auto"
                    bodyStyle={{ padding: 0 }}
                >
                    <div className="modal-content">
                        <Spin size="large" />
                        <p>Submitting...</p>
                    </div>
                </Modal>
            )}
            {locationAccessError && (
                <Modal
                    visible={locationAccessError}
                    title={language === 'en' ? locationInstructions.en.title : locationInstructions.sw.title}
                    onCancel={() => setLocationAccessError(false)}
                    footer={null}
                >
                    <p>{language === 'en' ? locationInstructions.en.message : locationInstructions.sw.message}</p>
                    <p>{language === 'en' ? locationInstructions.en.recommendation : locationInstructions.sw.recommendation}</p>
                    <p>{language === 'en' ? "To enable location access in Chrome, follow these steps:" : "Ili kuwezesha ufikiaji wa mahali katika Chrome, fuata hatua hizi:"}</p>
                    <ol>
                        {(language === 'en' ? locationInstructions.en.steps : locationInstructions.sw.steps).map((step, index) => (
                            <li key={index}>
                                {step.icon} {step.text}
                            </li>
                        ))}
                    </ol>
                    <Button type="primary" onClick={() => setLanguage(language === 'en' ? 'sw' : 'en')}>
                        {language === 'en' ? 'Switch to Swahili' : 'Switch to English'}
                    </Button>
                </Modal>
            )}
        </div>
    );
};

export default SurveyForm;
